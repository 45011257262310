module.exports.faq = [{
	title: "Was ist ein Strompaket und welche Vorteile bringt mir die Nutzung?",
	brief: 'Bei einem Strompaket kaufen Sie Strommengen auf Vorrat, ähnlich wie bei einem Prepaid-Guthaben.\n' +
		'Schützen Sie sich vor Preiserhöhungen und profitieren Sie von keiner Vertragslaufzeit.'
},
	{
		title: "Was passiert, wenn das Strompaket aufgebraucht ist?",
		brief: 'Wir informieren Sie rechtzeitig vor Ablauf Ihres Strompaketes, sofern Sie Ihren Zählerstand wie \n' +
			'vereinbart übermitteln. Wenn Ihr Strompaket aufgebraucht ist, haben Sie mehrere Möglichkeiten: \n' +
			'Entweder kaufen Sie ein neues Strompaket, schließen einen neuen Stromtarif ab oder falls Sie sich \n' +
			'für keine dieser Optionen entscheiden, beliefert Ihr örtlicher Grundversorger Sie mit Strom.' +
			'<p class="paragraph">Bitte berücksichtigen Sie, dass wir Ihnen noch keine genauen Preise für Folgestrompakete' +
			' nennen können, da wir als Stromanbieter keinen Einfluss auf die Veränderung von Steuern und Abgaben haben.</p>'
	},
	{
		title: "Wie kann ich Strompakete nachbestellen?",
		brief: 'Sie haben folgende Möglichkeiten ein Strompaket nachzubestellen: Entweder über den Link <a href="https://www.eprimo.de/service/contract/meter-readings/add" target="_blank">https://www.eprimo.de/service/contract/meter-readings/add</a> oder über unsere Hotline 0800-204011093 .'
	},
	{
		title: "Was passiert mit meinem bestehenden Stromvertrag?",
		brief: 'Um von den Vorteilen der Strompakete profitieren zu können, beenden wir Ihren laufenden Vertrag und rechnen diesen ab.'
	},
	{
		title: "Haben Strompakete ein Verfallsdatum? ",
		brief: 'Nein, die Strompakete haben keine Laufzeiten.'
	},
	{
		title: "Welche Voraussetzungen benötige ich, um ein Strompaket zu nutzen?",
		brief: ' Es bedarf keine Voraussetzungen. Sie müssen lediglich einen Zugang zu Ihrem Stromzähler haben, um den Stand regelmäßig ablesen zu können.'
	},
	{
		title: "Welche Zahlungsweisen sind möglich?",
		brief: 'Sie können sich zwischen einer Einmalzahlung oder einem monatlichen Ratenkauf entscheiden. Die Zahlungsart erfolgt entweder per Überweisung oder als Lastschrift.'
	},
	{
		title: "Was ist der Vorteil einer Einmalzahlung gegenüber der Ratenzahlung?",
		brief: 'Bei einer Einmalzahlung profitieren Sie von einem günstigeren Paketpreis. Sofern Sie den Betrag per\n' +
			'      Ratenzahlung bezahlen möchten, zahlen Sie die Summe über die Anzahl der Monate ab, die der\n' +
			'      angegebenen Reichweite des Strompakets entspricht. Einen Ratenzahlungsplan erhalten Sie\n' +
			'      selbstverständlich in Ihrem Begrüßungsschreiben.'
	},
	{
		title: "Wie ermittelt eprimo den Verbrauch, für die aktuellen Strompakete? ",
		brief: 'Um den Ablauf Ihres Strompaketes genau bestimmen zu können, benötigen wir regelmäßig Ihren\n' +
			'      Zählerstand. Diesen können Sie unter <a href="https://www.eprimo.de/service" target="_blank">mein Eprimo</a> übermitteln.\n' +
			'      Für die Zukunft planen wir eine automatische Zählerstandübermittlung durch einen digitalen Zähler.'
	},
	{
		title: "Wann beginnt ein Strompaket?",
		brief: 'Ab dem 1. des Folgemonats nach Bestellung beginnt Ihr Strompaket.'
	},
	{
		title: "An wen kann ich mich bei Fragen wenden?",
		brief: 'Sie können sich rund um die Uhr per Mail an strompakete@eprimo.de wenden, oder unseren\n' +
			'      Kundenservice von 8-18 Uhr unter der 0800-204011093 anrufen.'
	},
	{
		title: "Wo kommt die grüne Energie her?",
		brief: ' Um die Energiewende voranzutreiben, unterstützt eprimo den Ausbau regenerativer Energiequellen:\n' +
			'      Mit klimaneutral erzeugtem Strom aus Wasserkraft setzen wir auf eine umweltfreundliche\n' +
			'      Stromerzeugung, die bereits seit Jahrhunderten genutzt wird und zu den Klassikern der\n' +
			'      Energiegewinnung zählt.\n' +
			'      Wir können mehr bieten als nur attraktive Tarife und Konditionen: Dank unseres CO2-neutralen\n' +
			'      Ökostroms tragen wir aktiv dazu bei, mit umweltfreundlicher Energie den ökologischen Fußabdruck\n' +
			'      zu verringern.'
	},
	{
		title: "Wie setzt sich der eprimo-Strom zusammen?",
		brief: 'Wir unterstützen den schonenden Umgang mit wertvollen Ressourcen. Unsere Stromtarife enthalten daher bereits ' +
			'91,2% Strom aus erneuerbaren Energien. Damit Sie nachvollziehen können, aus welchen unterschiedlichen Energieträgern ' +
			'der eprimo-Strommix besteht, sorgen wir für mehr Transparenz:<br>' +
			'<div class="faq-diagram"></div>' +
			'<ul class="faq-list">' +
			'<li>Kohle: 6,5%</li>' +
			'<li>Erneuerbare Energien gefördert nach der EEG: 60,3%</li>' +
			'<li>Sonstige erneuerbare Energien: 30,9%</li>' +
			'<li>Kernkraft: 1,9%</li>' +
			'<li>Erdgas: 0,3%</li>' +
			'<li>Sonstige fossile Energien: 0,1%</li>' +
			'<li class="paragraph">CO2-Emissionen: 67 g/kWh</li>' +
			'<li>Radioaktiver Abfall: 0,0001 g /kWh</li>' +
			'</ul>' +
			'(Stromkennzeichnung der eprimo GmbH, gemäß §42 Energiewirtschaftsgesetz, Stand der Information: 1. November 2020)<br><br>'
	}
]

module.exports.strom = [{
	title: "Was ist ein SmartMeter und worin besteht der Unterschied zu einem analogen Stromzähler?",
	brief: 'Ein digitaler Stromzähler misst den Stromverbrauch des Nutzers und speichert, anders als ein ' +
		'analoger Ferraris-Zähler, die Verbrauchswerte in Tages-, Wochen- und Monatswerten der letzten zwei Jahre. ' +
		'Je nach Zählertyp können die Verbrauchswerte auch fernausgelesen und in Echtzeit visualisiert werden.'
},
	{
		title: "Wozu benötige ich einen SmartMeter?",
		brief: 'Unser SmartMeter bietet Ihnen dank innovativer Technologie mehr Komfort. Die Fernauslesung ' +
			'übermittelt uns automatisch Ihren aktuellen Zählerstand und erspart Ihnen den monatlichen Gang in den ' +
			'Keller. Somit können wir die Reichweite Ihres Strompakets noch präziser bestimmen und Sie bei ' +
			'überdurchschnittlich hohem Verbrauch warnen.'
	},
	{
		title: "Muss ich für den SmartMeter monatlich zahlen?",
		brief: 'Nein, Sie gehören zu einer ausgewählten, exklusiven Testgruppe, der wir den SmartMeter kostenlos zur ' +
			'Verfügung stellen. Dieses Angebot ist nur in Verbindung mit dem Abschluss eines Strompakets gültig.'
	},
	{
		title: "Wem gehört der SmartMeter?",
		brief: 'Der Stromzähler gehört dem Messstellenbetreiber. Dieser ist für Ein- und Ausbau, Betrieb, Wartung und das Monitoring des Zählers zuständig.'
	},
	{
		title: "Kann ich den SmartMeter auch als Mieter einbauen lassen?",
		brief: 'Ja, als Mieter haben Sie, genau wie bei der Wahl ihres Stromvertrags, das Recht einen Messstellenbetreiber frei zu wählen und somit auch einen Zähler einbauen zu lassen.'
	},
	{
		title: "Wie läuft der Zählerwechsel ab?",
		brief: 'Der Wechsel Ihres Stromzählers ist ganz einfach: Zunächst vereinbaren wir mit Ihnen einen für Sie möglichst komfortablen Installationstermin, ' +
			'zu dem unser Techniker bei Ihnen vor Ort den Zählerwechsel vornimmt. Am Tag der Installation müssen Sie dem Techniker Zugang zu Ihrem Zählerschrank ' +
			'gewähren. Nach erfolgreichem Umbau nimmt unser Techniker Ihren alten Zähler mit und übermittelt den letzten Zählerstand an uns. Anschließend wird der ' +
			'Zähler entweder an Ihren alten Messstellenbetreiber zurückgegeben oder entsorgt.'
	},
	{
		title: "Welche technischen Voraussetzungen benötige ich für den SmartMeter?",
		brief: 'Für den Wechsel des Stromzählers muss der Zugang zu dem Zählerschrank ' +
			'für unseren Techniker gewährleistet werden. Zudem behalten wir uns vor, nur an ' +
			'Zählerschränken zu montieren, die dem aktuellen Stand der Technik entsprechen. Sollten Sie dazu Fragen' +
			'haben, schicken Sie uns einfache eine Mail mit Fotos von Ihrem Zählerschrank an <a href="mailto:https:strompakete@eprimo.de" >strompakete@eprimo.de</a> .'
	},
	{
		title: "Wie funktioniert die Datenübertragung und wie werden die Daten geschützt?",
		brief: 'Die Zählerdaten werden über das Mobilfunknetz uns übermittelt. Die Kommunikation über LTE ermöglicht ' +
			'eine schnelle und sichere Datenübertragung. Der Schutz der Daten ist dabei durch das MsbG und die Richtlinien des ' +
			'Bundesamts für Sicherheit in der Informationstechnik gewährleistet.'
	}

]