<template>
  <li class="accordion-item">
    <div
      class="accordion-trigger"
      :class="{ 'accordion-trigger-active': visible }"
      @click="open"
    >
      <slot name="accordion-trigger"></slot>
      <div class="arrow" :class="{ 'arrow-active': visible }"></div>
    </div>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div class="accordion-content" v-show="visible">
        <ul>
          <slot name="accordion-content"></slot>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  inject: ['Accordion'],

  data() {
    return {
      index: null
    };
  },

  computed: {
    visible() {
      return this.index == this.Accordion.active;
    }
  },

  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    end(el) {
      el.style.height = '';
    }
  },
  
  created() {
    this.index = this.Accordion.count++;
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
