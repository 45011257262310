<template>
  <div class="accordion-faq">
    <accordion-base>
      <slot class="accordion-title" name="title"></slot>

      <accordion-item v-for="(info, idx) in list" :key="idx">
        <!-- This slot will handle the title/header of the accordion and is the part you click on -->
        <template slot="accordion-trigger">
          <h3 class="accordion-trigger-title">{{ info.title }}</h3>
        </template>

        <!-- This slot will handle all the content that is passed to the accordion -->
        <template slot="accordion-content">
          <div class="accordion-content-text" v-html="info.brief" />
        </template>
      </accordion-item>
    </accordion-base>
  </div>
</template>

<script>
import AccordionBase from '@/components/next/accordion/accordion-base.vue';
import AccordionItem from '@/components/next/accordion/accordion-item.vue';
import { faq, strom } from '@/placeholder/faq';

export default {
  components: { AccordionBase, AccordionItem },

  props: {
    item: {
      type: String,
      default: 'faq'
    }
  },

  data() {
    return {
      faq,
      strom
    };
  },

  computed: {
    list() {
      return this[this.item];
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
